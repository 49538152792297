import { useState } from "react";
import { apiClient } from "../../../../repositories/apiclient";

const CustomerDetails = ({ userData, setUserData }) => {
	const [data, setData] = useState({
		fullName: userData?.fullName ?? '',
		email: userData?.email ?? '',
		orgName: userData?.orgName ?? '',
		designation: userData?.designation ?? '',
	});

	const handleChange = (e) => {
		setData({ ...data, [e.target.name]: e.target.value });
	};

	const handleUpdate = () => {
		apiClient.put(`/users/${userData.id}`, data).then(res => {
			// console.log(res);
			setUserData(res.data);
		}).catch(err => {
			// console.log(err);
		});
	};

	return (
		<main className='customerDetails'>
			<h1>User Details</h1>
			<div className='customerDetails__Container'>
				<div className='customerDetails__Left'>
					<img
						src='https://images.unsplash.com/photo-1511367461989-f85a21fda167?q=80&w=1631&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
						alt='Customer'
					/>
				</div>
				<div className='customerDetails__Right'>
					<div className='customerDetails__Row'>
						<h3>Name :</h3>
						<input
							type='text'
							name='fullName'
							value={data.fullName}
							onChange={handleChange}
						/>
					</div>
					<div className='customerDetails__Row'>
						<h3>Email :</h3>
						<input
							type='text'
							name='email'
							value={data.email}
							onChange={handleChange}
							disabled
						/>
					</div>
					<div className='customerDetails__Row'>
						<h3>Organisation Name :</h3>
						<input
							type='text'
							name='orgName'
							value={data.orgName}
							onChange={handleChange}
						/>
					</div>
					<div className='customerDetails__Row'>
						<h3>Designation :</h3>
						<input
							type='text'
							name='designation'
							value={data.designation}
							onChange={handleChange}
						/>
					</div>
					<div className='customerDetails__Btn'>
						<button type='button' onClick={handleUpdate}>Update</button>
					</div>
				</div>
			</div>
		</main>
	);
};

export default CustomerDetails;
