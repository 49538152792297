import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from '@tanstack/react-form';
import { yupValidator } from '@tanstack/yup-form-adapter';
import * as yup from 'yup';
import { Button } from '../../components/index.js';
import 'react-intl-tel-input/dist/main.css';
import Token from '../../lib/token.js';
import {
	fetchOtpLogin,
	fetchLogin,
	setCred,
	setUserCred,
} from '../../store/reducers/creds.js';

import CookieToken from '../../lib/cookie-token.js';
import {
	ACCESS_TOKEN_KEY,
	REFRESH_TOKEN_KEY,
} from '../../constants/token.constant.js';

import { useCountdownTimer } from '../../hooks/hooks';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { jwtDecode } from 'jwt-decode';

// function FieldInfo({ field }) {
// 	return (
// 		<div className='text-[1.4rem] font-[500] text-red-500'>
// 			{field.state.meta.touchedErrors ? (
// 				<p>{field.state.meta.touchedErrors}</p>
// 			) : null}
// 		</div>
// 	);
// }

function FieldInfo({ field }) {
	return (
		<div className='text-[1.4rem] font-[500] text-red-500'>
			{field.state.meta.errors ? (
				<p>{field.state.meta.errors[0]}</p>
			) : null}
		</div>
	);
}
const Login = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [resendTimeCount, setResendTimeCount] = useState(0);
	const loginCredentials = useSelector(
		(state) => state.cred
	);
	const otpid = useSelector((state) => state.otpLogin);
	const loginSuccess = useSelector((state) => state.login);
	const [error, setError] = useState('');
	const [seconds, resetTimer] = useCountdownTimer();
	const handleInitiate = (value) => {
		const { email, password } = value;
		setError('');
		// dispatch(
		// 	fetchOtpLogin({
		// 		email,
		// 		password,
		// 	})
		// );
		dispatch(
			fetchLogin({
				identifier: email,
				password,
			})
		);
		dispatch(
			setCred({
				email,
				password,
			})
		);
		resetTimer();
	};
	const handlefinal = (value) => {
		const { otp } = value;
		setError('');
		const id = otpid.data.otpId;
		dispatch(
			fetchLogin({
				otp,
				otpId: id,
			})
		);
	};
	useEffect(() => {
		if (loginSuccess.data) {
			CookieToken.setToken(
				ACCESS_TOKEN_KEY,
				loginSuccess.data.jwt
			);
			// Token.setToken(
			// 	REFRESH_TOKEN_KEY,
			// 	loginSuccess.data.refreshToken
			// );
			dispatch(
				setUserCred({
					// accessToken: loginSuccess.data.accessToken,
					accessToken: loginSuccess.data.jwt,
					email: loginSuccess.data.user.email,
					// image: loginSuccess.data.image,
					username: loginSuccess.data.user.username,
				})
			);
			navigate('/dashboard', { replace: true });
		}
	}, [loginSuccess]);
	const initiateForm = useForm({
		defaultValues: {
			email: '',
			password: '',
		},
		onSubmit: async ({ value }) => handleInitiate(value),
		validatorAdapter: yupValidator(),
	});
	const finalForm = useForm({
		defaultValues: {
			otp: '',
		},
		onSubmit: async ({ value }) => handlefinal(value),
		validatorAdapter: yupValidator(),
	});

	useEffect(() => {
		if (
			localStorage.getItem('userData') ||
			(
				document.cookie &&
				document.cookie.split('; ').find(row => row.startsWith(`${ACCESS_TOKEN_KEY}=`))?.split('=')[1] &&
				jwtDecode(document.cookie.split('; ').find(row => row.startsWith(`${ACCESS_TOKEN_KEY}=`))?.split('=')[1]) &&
				jwtDecode(document.cookie.split('; ').find(row => row.startsWith(`${ACCESS_TOKEN_KEY}=`))?.split('=')[1]).exp * 1000 > Date.now()
			)
		) {
			navigate('/dashboard');
		}
	}, []);

	return (
		<main className='login'>
			<Helmet>
				<title>Signin - Horizon Market Strategies</title>
				<meta
					name='description'
					content='Horizon Market Strategies (HMS), your trusted partner in delivering bespoke market research solutions aimed at equipping businesses with actionable insights.'
				/>
				<meta
					property='og:title'
					content='Home - Horizon Market Strategies'
				/>
				<meta
					property='og:description'
					content='Horizon Market Strategies (HMS), your trusted partner in delivering bespoke market research solutions aimed at equipping businesses with actionable insights.'
				/>
				<meta
					property='og:site_name'
					content='Horizon Market Strategies'
				/>
			</Helmet>
			<div className='login__Container'>
				<div className='login__Left'>
					<img src={'/HMSLogo.png'} alt='Logo' />
					<div className='login__header'>
						<h1>Log in to your Account</h1>
						<p>Welcome back! Select method to log in:</p>
					</div>
					<div className='login__form'>
						{!otpid.data ? (
							<form
								onSubmit={(e) => {
									e.preventDefault();
									e.stopPropagation();
									initiateForm.handleSubmit();
								}}>
								<initiateForm.Field
									name='email'
									validators={{
										onChange: yup
											.string()
											.email('Invalid email address')
											.required('Email is required'),
									}}
									children={(field) => (
										<div className='flex flex-col items-start w-full'>
											<div className='form__Input flex items-center gap-[1rem] w-full  bg-[#D9D9D9]'>
												<img
													src={'/emailIcon.svg'}
													alt=' '
												/>
												<input
													placeholder='Email'
													value={field.state.value}
													onBlur={field.handleBlur}
													onChange={(e) =>
														field.handleChange(
															e.target.value
														)
													}
												/>
											</div>

											<FieldInfo field={field} />
										</div>
									)}
								/>

								<initiateForm.Field
									name='password'
									validators={{
										onChange: yup
											.string()
											.required('Password is required'),
										onChangeAsyncDebounceMs: 500,
										onChangeAsync: yup
											.string()
											.test(
												'no error',
												"No 'error' allowed in password",
												async (value) => {
													await new Promise((resolve) =>
														setTimeout(resolve, 1000)
													);
													return !value?.includes('error');
												}
											),
									}}
									children={(field) => (
										<div className='flex flex-col items-start w-full'>
											<div className='form__Input flex items-center gap-[1rem]  w-full'>
												<img
													src={'/passwordIcon.svg'}
													alt=''
												/>
												<input
													placeholder='Password'
													value={field.state.value}
													onBlur={field.handleBlur}
													type='password'
													onChange={(e) =>
														field.handleChange(
															e.target.value
														)
													}
												/>
											</div>

											<FieldInfo field={field} />
										</div>
									)}
								/>
								{error ? (
									<p className='text-red-600'>{error}</p>
								) : null}
								<initiateForm.Subscribe
									selector={(state) => [
										state.canSubmit,
										state.isSubmitting,
									]}
									children={([canSubmit, isSubmitting]) => (
										<button
											type='submit'
											disabled={!canSubmit}
											className=' @apply w-full bg-[#042E3E] px-[1rem] py-[1.5rem] text-[#FFF] text-[1.8rem] font-[700];'>
											{isSubmitting ? '...' : 'Submit'}
										</button>
									)}
								/>
							</form>
						) : (
							<form
								onSubmit={(e) => {
									e.preventDefault();
									finalForm.handleSubmit();
								}}>
								<div className=''>
									<finalForm.Field
										name='otp'
										validators={{
											onChange: yup
												.string()
												.required('OTP is required')
												.min(
													6,
													'OTP must be 6 character long'
												),
											onChangeAsyncDebounceMs: 500,
											onChangeAsync: yup
												.string()
												.test(
													'no error',
													"No 'error' allowed in OTP",
													async (value) => {
														await new Promise((resolve) =>
															setTimeout(resolve, 1000)
														);
														return !value?.includes(
															'error'
														);
													}
												),
										}}
										children={(field) => (
											<div className='flex flex-col items-start w-full'>
												<div className='form__Input flex items-center gap-[1rem] w-full'>
													<input
														placeholder='OTP'
														value={field.state.value}
														onBlur={field.handleBlur}
														onChange={(e) =>
															field.handleChange(
																e.target.value
															)
														}
													/>
												</div>

												<FieldInfo field={field} />
											</div>
										)}
									/>
								</div>
								{error ? (
									<p className='text-red-600'>{error}</p>
								) : null}
								<finalForm.Subscribe
									selector={(state) => [
										state.canSubmit,
										state.isSubmitting,
									]}
									children={([canSubmit, isSubmitting]) => (
										<button
											type='submit'
											disabled={!canSubmit}
											className='  w-full bg-[#042E3E] px-[1rem] py-[1.5rem] text-[#FFF] text-[1.8rem] font-[700];'>
											{isSubmitting ? '...' : 'Submit'}
										</button>
									)}
								/>
								<Button
									type='button'
									className={`auth__Button mt-[2.5rem] ${seconds > 0 ? 'bg-gray-700 ' : ''
										}`}
									disabled={seconds > 0}
									onClick={() => {
										dispatch(
											fetchOtpLogin({
												email: loginCredentials.email,
												password: loginCredentials.password,
											})
										);
										setResendTimeCount(resendTimeCount + 1);
										resetTimer();
									}}>
									Resend{' '}
									{seconds > 0 ? (
										<span className='countdown'>
											<span
												style={{ '--value': seconds }}
											/>
										</span>
									) : (
										''
									)}
								</Button>
							</form>
						)}
						<div className='login__createAccount'>
							<p>
								Don&apos;t have an account?{' '}
								<span>
									<Link to='/sign-up'>
										Create an account
									</Link>
								</span>
							</p>
						</div>
					</div>
					<Link to="/forgot-password" className='text-[#868686] font-[500] text-[1.6rem] w-full text-center'>Lost your password?</Link>
				</div>
				<div className='login__Right'>
					<img
						src={'/LoginSideBg.svg'}
						alt='LoginRightBG'
					/>
				</div>
			</div>
		</main>
	);
};

export default Login;
