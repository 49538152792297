export const config = {
    frontend: {
        baseUrl: 'https://horizon-markets.com',
    },
    backend: {
        strapi: "https://cms.horizon-markets.com",
        stripe: "https://hms-mailer.darkrecon.cloud"
    },
    strapi_api: "78854af519281144014d0e3c2693bcd5f783643758b8ca9fd4fdcf5d267545e6635bc60b23b9d1a3aaabc8c9fcf310b603518a76b0d023db287bd16270233ed1097c9233689c6eac13418b902ceda4d00eae292a0bd180ac8630d16d23b50f3a13253622b92a6b7148fec3c0b579a84e01d11cb7066b4a4757b58d7392b0f022",
    captcha_site_api: "6LfRdZoqAAAAAElv3rfT5dmv12Zjv5VP4it34_5V",
    gtm_id: "GTM-N78DPWNP"
}
