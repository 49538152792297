import { useState } from "react"
import { Button } from "../../../components"
import OrdersList from "./List/OrdersList.router"
import OrderDetails from "./Details/OrdersDetails.router"
import OrdersActions from "./Actions/OrdersActions.router"

const Orders = ({ userEmail }) => {
  const [view, setView] = useState(0)

  const showView = () => {
    switch (view) {
      case 0:
        return <OrdersList userEmail={userEmail} />;
      case 1:
        return <OrderDetails />;
      case 2:
        return <OrdersActions />;
    }
  }

  return (
    <div className="Orders__Container">
      <h1 className="Orders__Heading">Orders Section</h1>
      {/* <div className="Orders__BtnContainer">
        <Button text="Order List" onClick={() => setView(0)} className={view == 0 ? 'Orders__NavBtns Orders__BtnActive' : 'Orders__NavBtns'} />
        <Button text="Order Details" onClick={() => setView(1)} className={view == 1 ? 'Orders__NavBtns Orders__BtnActive' : 'Orders__NavBtns'} />
        <Button text="Order Actions" onClick={() => setView(2)} className={view == 2 ? 'Orders__NavBtns Orders__BtnActive' : 'Orders__NavBtns'} />
      </div> */}

      {/* <div>
        {showView()}
      </div> */}

      <OrdersList userEmail={userEmail} />
    </div>
  )
}

export default Orders
