import { useEffect, useState } from "react";
import { NewsCard } from "../../components";
import data from "../../constants/static.json";
import { Helmet } from "react-helmet";
import { apiClient } from "../../repositories/apiclient";

const News = () => {
    const [newsCardsData, setNewsCardsData] = useState(data.newsCardsData);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 6;

    const fetchNewsCardsData = async () => {
        const res = await apiClient({
            method: 'get',
            url: `/industry-newses?sort=publishDate:desc&pagination[page]=${currentPage}&pagination[pageSize]=${itemsPerPage}&populate=*`
        })
        setNewsCardsData(res.data.data);
    }

    useEffect(() => {
        fetchNewsCardsData();
    }, []);

    // Calculate total pages
    const totalPages = Math.ceil(newsCardsData.length / itemsPerPage);

    // Calculate the data to display for the current page
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const currentItems = newsCardsData.slice(startIndex, endIndex);

    // Handle page change
    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    return (
        <main className="news">
            <Helmet>
                <title>Industry News - Horizon Market Strategies</title>
                <meta
                    name='description'
                    content='Horizon Market Strategies (HMS), your trusted partner in delivering bespoke market research solutions aimed at equipping businesses with actionable insights.'
                />
                <meta
                    property='og:title'
                    content='Home - Horizon Market Strategies'
                />
                <meta
                    property='og:description'
                    content='Horizon Market Strategies (HMS), your trusted partner in delivering bespoke market research solutions aimed at equipping businesses with actionable insights.'
                />
                <meta
                    property='og:site_name'
                    content='Horizon Market Strategies'
                />
            </Helmet>
            <img src="/industryNewsBanner.jpg" alt="industry-news-banner" />
            <div className="news__Wrapper md:px-custom">
                <h3>Welcome to Our Industry News Hub</h3>
                <p>At Horizon Market Strategies, we're committed to serving the industry better. As a thought leader, we're dedicated to providing you with the latest insights and trends in various industries.
                </p>
                <h1>Why Visit Our <span>Industry News Hub?</span></h1>
                <ol>
                    <li>Stay up-to-date with the latest industry trends and insights </li>
                    <li>Gain a competitive edge with our in-depth statistics and predictions </li>
                    <li>Discover new opportunities and challenges in your industry  </li>
                    <li>Learn from our expert analysis and research </li>
                </ol>
                <h3>Join Our Community</h3>
                <p>At Horizon Market Strategies, we're passionate about serving the industry better. Join our community today and stay ahead of the curve with our Industry News Hub.
                </p>

                {/* NEWS CARDS CONTAINER */}
                <div className="news__CardsWrapper">
                    <h3>//Industry News</h3>
                    <div className="news__Cards grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
                        {
                            currentItems.map((item) => (
                                <NewsCard data={item} key={item.id} />
                            ))
                        }
                    </div>

                    {/* PAGINATION */}
                    <div className="pagination flex gap-[1.5rem] justify-center items-center mt-[5rem]">
                        {currentPage > 1 && (
                            <button
                                className="outline-none text-[1.8rem] font-[700] flex items-center gap-[0.5rem] bg-[#042E3E]"
                                onClick={() => handlePageChange(currentPage - 1)}
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2.5} stroke="#000" className="size-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
                                </svg>

                                Prev
                            </button>
                        )}
                        {[...Array(totalPages)].map((_, index) => (
                            <button
                                key={index}
                                className={`px-[1.5rem] py-[0.5rem] text-black rounded text-[1.8rem] font-[700] ${currentPage === index + 1 ? 'bg-[#042E3E] text-white' : ''}`}
                                onClick={() => handlePageChange(index + 1)}
                            >
                                {index + 1}
                            </button>
                        ))}
                        {currentPage < totalPages && (
                            <button
                                className="outline-none text-[1.8rem] font-[700] flex items-center gap-[0.5rem] bg-[#042E3E]"
                                onClick={() => handlePageChange(currentPage + 1)}
                            >
                                Next
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2.5} stroke="#000" className="size-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                                </svg>
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </main>
    );
};

export default News;
