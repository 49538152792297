import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Sidebar } from '../../../components';
import Feedback from '../Feedback/Feedback.router';
import Customers from '../Customers/List/Customers.router';
import CustomerDetails from '../Customers/Details/CustomerDetails.router';
import Overview from '../Overview/Overview.router';
import Orders from '../Orders/Orders.router';
import Products from '../Products/Products.router';
import Marketing from '../Marketing/Marketing.router';
import Reports from '../Reports/Reports.router';
import Settings from '../Settings/Settings.router';
import SystemAlerts from '../Notifications/SystemAlerts.router';
import CustomerNotif from '../Notifications/CustomerNotif.router';
import CustomerSupport from '../Support/Support.router';
import CustomerAddress from '../Customers/CustomerAddress.router';

import { apiClient } from "../../../repositories/apiclient";
import { jwtDecode } from "jwt-decode";
import { ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY } from "../../../constants/token.constant";

const AdminDashboard = () => {
	const [activeTab, setActiveTab] = useState('Dashboard');
	const [userData, setUserData] = useState(null);
	const navigate = useNavigate();
	const [isLoading, setIsLoading] = useState(false);

	const fetchUser = () => {
		setIsLoading(true);
		if (!document.cookie) return navigate('/sign-in');

		const accessToken = document.cookie.split('; ').find(row => row.startsWith(`${ACCESS_TOKEN_KEY}=`))?.split('=')[1];

		if (!accessToken) return navigate('/sign-in');

		const decodedToken = jwtDecode(accessToken);

		if (!decodedToken || (decodedToken && decodedToken.exp * 1000 < Date.now())) {
			document.cookie = `${ACCESS_TOKEN_KEY}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
			document.cookie = `${REFRESH_TOKEN_KEY}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
			localStorage.removeItem('userData');
			return navigate('/sign-in');
		}

		const userId = decodedToken.id;
		
		apiClient.get(`/users/${userId}`)
		.then((res) => {
			setUserData(res.data);
			localStorage.setItem('userData', JSON.stringify(res.data));
			setIsLoading(false);
		})
		.catch((err) => {
			setIsLoading(false);
		})
	}

	useEffect(() => {
		fetchUser();
	}, []);

	const renderContent = () => {
		switch (activeTab) {
			case 'Dashboard':
				return <Overview username={userData?.username} />;
			case 'Orders':
				return <Orders userEmail={userData?.email} />;
			// case 'Products':
			// 	return <Products />;
			// case 'Details':
			// 	return <Customers />;
			case 'Manage':
				return <CustomerDetails userData={userData} setUserData={setUserData} />;
			// case 'Marketing':
			// 	return <Marketing />;
			// case 'Reports':
			// 	return <Reports />;
			// case 'Settings':
			// 	return <Settings />;
			case 'Address':
				return <CustomerAddress userData={userData} setUserData={setUserData} />;
			// case 'System Alerts':
			// 	return <SystemAlerts />;
			// case 'Customer Notifications':
			// 	return <CustomerNotif />;
			// case 'Customer Support':
			// 	return <CustomerSupport />;
			// case 'Feedback and Reviews':
			// 	return <Feedback />;
			default:
				return <div>Select a tab</div>;
		}
	};

	if (isLoading) return <div></div>;

	return (
		<main className='adminDashboard'>
			<section className='adminDashboard__Wrapper'>
				<div className='adminDashboard__Sidebar'>
					<Sidebar onTabChange={setActiveTab} />
				</div>
				<section className='adminDashboard__Body'>
					{renderContent()}
				</section>
			</section>
		</main>
	);
};

export default AdminDashboard;
