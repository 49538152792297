import { createSelector } from '@reduxjs/toolkit';
const selectReportState = (state) => state.report;
export const selectReport = createSelector(
	[selectReportState],
	(state) => state
);
const selectDetailedReportState = (state) =>
	state.detailedReport;
export const selectDetailedReport = createSelector(
	[selectDetailedReportState],
	(state) => state
);
const selectPublishReportState = (state) =>
	state.PublishReport;
export const selectPublishReport = createSelector(
	[selectPublishReportState],
	(state) => state
);
