import { useState } from "react";
import { apiClient } from "../../../repositories/apiclient";

const CustomerAddress = ({ userData, setUserData }) => {
	const [data, setData] = useState({
		addressLine1: userData?.addressLine1 ?? '',
		addressLine2: userData?.addressLine2 ?? '',
		stateOrProvince: userData?.stateOrProvince ?? '',
		country: userData?.country ?? '',
		zipCode: userData?.zipCode ?? '',
	});

	const handleChange = (e) => {
		setData({ ...data, [e.target.name]: e.target.value });
	};

	const handleUpdate = () => {
		apiClient.put(`/users/${userData.id}`, data).then(res => {
			setUserData(res.data);
		}).catch(err => {
			// console.log(err);
		});
	};

	return (
		<main className='customerDetails'>
			<h1>Your Address</h1>
			<div className='customerDetails__Container'>
				<div className='customerDetails__Right'>
					<div className='customerDetails__Row'>
						<h3>Address Line 1 :</h3>
						<input
							type='text'
							value={data?.addressLine1}
							name='addressLine1'
							onChange={handleChange}
						/>
					</div>
					<div className='customerDetails__Row'>
						<h3>Address Line 2 :</h3>
						<input
							type='text'
							value={data?.addressLine2}
							name='addressLine2'
							onChange={handleChange}
						/>
					</div>
					<div className='customerDetails__Row'>
						<h3>State or Province :</h3>
						<input
							type='text'
							value={data?.stateOrProvince}
							name='stateOrProvince'
							onChange={handleChange}
						/>
					</div>
					<div className='customerDetails__Row'>
						<h3>Country :</h3>
						<input
							type='text'
							value={data?.country}
							name='country'
							onChange={handleChange}
						/>
					</div>
					<div className='customerDetails__Row'>
						<h3>Zip Code :</h3>
						<input
							type='text'
							value={data?.zipCode}
							name='zipCode'
							onChange={handleChange}
						/>
					</div>
					<div className='customerDetails__Btn'>
						<button type='button' onClick={handleUpdate}>Update</button>
					</div>
				</div>
			</div>
		</main>
	);
};

export default CustomerAddress;
