import { useState } from 'react';
import Button from '../Button/Button.component';
import { Link } from 'react-router-dom';

const Accordion = ({ data }) => {
	const [open, setOpen] = useState(null);

	const handleOpen = (index) => {
		setOpen(index === open ? null : index);
	};

	return (
		<section className='accordion'>
			<article className='accordion__Card'>
				<div onClick={() => handleOpen(data.id)} className='accordion__Container'>
					<span>{data.heading}</span>
					<svg
						xmlns='http://www.w3.org/2000/svg'
						fill='none'
						viewBox='0 0 24 24'
						strokeWidth={3}
						stroke='currentColor'
						className='size-6'>
						<path
							strokeLinecap='round'
							strokeLinejoin='round'
							d='M12 4.5v15m7.5-7.5h-15'
						/>
					</svg>
				</div>
				<div>
					{open === data.id && (
						<section className='accordion__Visible'>
							{data.details.includes("contact form") ? (
								<>
									{data.details.split("contact form")[0]}

									<Link to='/contact' className="cursor-pointer underline-noen font-[500]">
										contact form
									</Link>

									{data.details.split("contact form")[1]}
								</>
							) : (
								data.details
							)}
						</section>
					)}
				</div>
			</article>
		</section>
	);
};

export default Accordion;
// Career Accordion :
export const CareerAccordion = ({ data }) => {
	const [open, setOpen] = useState();

	const handleOpen = (index) => {
		setOpen(index === open ? null : index);
	};

	return (
		<section
			data-testid='career-accordion'
			className='careerAccordion'>
			<article className='careerAccordion__Card'>
				<div
					onClick={() => handleOpen(data.id)}
					className={
						open === data.id
							? 'careerAccordion__Select'
							: 'careerAccordion__Normal'
					}>
					<div className='careerAccordion__Text'>
						{data.city}
					</div>
					<div className='careerAccordion__Text'>
						{data.contract}
					</div>
					<div className='careerAccordion__Role'>
						{data.role}
					</div>
				</div>
				<div>
					{open === data.id && (
						<section className='careerAccordion__Visible'>
							<div className='flex-1'></div>
							<div className='flex-1'></div>
							<div className='careerAccordion__VisibleData'>
								{data.details}
								<Button
									className='button__White uppercase'
									text='Apply'
								/>
							</div>
						</section>
					)}
				</div>
			</article>
		</section>
	);
};
