import { useEffect, useState } from 'react';
import './OrdersList.styles.css';
import { apiClient } from '../../../../repositories/apiclient';
import { Button } from '../../../../components';

// const orders = [
// 	{
// 		id: '17823642763',
// 		customer_name: 'John Doe',
// 		order_date: '2024-02-21',
// 		status: 'Pending',
// 		total_amount: '$12,565.76',
// 	},
// 	{
// 		id: '78234567234',
// 		customer_name: 'Jane Smith',
// 		order_date: '2024-03-15',
// 		status: 'Processing',
// 		total_amount: '$8,245.50',
// 	},
// 	{
// 		id: '98347682394',
// 		customer_name: 'Mike Johnson',
// 		order_date: '2024-04-02',
// 		status: 'Shipped',
// 		total_amount: '$5,743.20',
// 	},
// 	{
// 		id: '67238947821',
// 		customer_name: 'Emily Brown',
// 		order_date: '2024-04-28',
// 		status: 'Delivered',
// 		total_amount: '$15,980.00',
// 	},
// 	{
// 		id: '12987463278',
// 		customer_name: 'David Wilson',
// 		order_date: '2024-05-10',
// 		status: 'Pending',
// 		total_amount: '$3,560.30',
// 	},
// 	{
// 		id: '56237896547',
// 		customer_name: 'Sarah Lee',
// 		order_date: '2024-06-07',
// 		status: 'Processing',
// 		total_amount: '$10,200.75',
// 	},
// 	{
// 		id: '45678901234',
// 		customer_name: 'Michael Davis',
// 		order_date: '2024-06-22',
// 		status: 'Pending',
// 		total_amount: '$7,890.40',
// 	},
// 	{
// 		id: '78901234567',
// 		customer_name: 'Jessica Taylor',
// 		order_date: '2024-07-01',
// 		status: 'Shipped',
// 		total_amount: '$6,450.90',
// 	},
// 	{
// 		id: '23456789012',
// 		customer_name: 'Alex Johnson',
// 		order_date: '2024-07-15',
// 		status: 'Delivered',
// 		total_amount: '$9,300.60',
// 	},
// 	{
// 		id: '89012345678',
// 		customer_name: 'Olivia Martinez',
// 		order_date: '2024-08-03',
// 		status: 'Pending',
// 		total_amount: '$11,750.25',
// 	},
// 	{
// 		id: '34567890123',
// 		customer_name: 'Sophia Clark',
// 		order_date: '2024-08-18',
// 		status: 'Processing',
// 		total_amount: '$6,780.50',
// 	},
// 	{
// 		id: '67890123456',
// 		customer_name: 'James Anderson',
// 		order_date: '2024-09-05',
// 		status: 'Shipped',
// 		total_amount: '$9,870.25',
// 	},
// 	{
// 		id: '90123456789',
// 		customer_name: 'Isabella White',
// 		order_date: '2024-09-22',
// 		status: 'Delivered',
// 		total_amount: '$15,340.80',
// 	},
// 	{
// 		id: '23456789012',
// 		customer_name: 'Daniel Harris',
// 		order_date: '2024-10-08',
// 		status: 'Pending',
// 		total_amount: '$12,600.00',
// 	},
// 	{
// 		id: '56789012345',
// 		customer_name: 'Ava Garcia',
// 		order_date: '2024-10-25',
// 		status: 'Processing',
// 		total_amount: '$7,890.30',
// 	},
// 	{
// 		id: '89012345678',
// 		customer_name: 'William Martinez',
// 		order_date: '2024-11-11',
// 		status: 'Shipped',
// 		total_amount: '$8,500.75',
// 	},
// 	{
// 		id: '12345678901',
// 		customer_name: 'Mia Lopez',
// 		order_date: '2024-11-28',
// 		status: 'Delivered',
// 		total_amount: '$11,200.60',
// 	},
// 	{
// 		id: '45678901234',
// 		customer_name: 'Ethan Robinson',
// 		order_date: '2024-12-15',
// 		status: 'Pending',
// 		total_amount: '$5,400.90',
// 	},
// 	{
// 		id: '78901234567',
// 		customer_name: 'Emma Baker',
// 		order_date: '2025-01-02',
// 		status: 'Processing',
// 		total_amount: '$13,750.40',
// 	},
// 	{
// 		id: '01234567890',
// 		customer_name: 'Noah Young',
// 		order_date: '2025-01-19',
// 		status: 'Shipped',
// 		total_amount: '$6,980.20',
// 	},
// ];

const OrdersList = ({ userEmail }) => {
	const [visibleOrders, setVisibleOrders] = useState(10);
	const [searchQuery, setSearchQuery] = useState('');
	const [orders, setOrders] = useState([]);

	const fetchOrders = async () => {
		await apiClient.get('/orders?populate=*')
			.then((res) => {
				const result = res.data.data.filter(order => order.customerEmail === userEmail);
				setOrders(result);
			})
			.catch((err) => {
				// console.log(err);
			});
	};

	useEffect(() => {
		fetchOrders();
	}, []);

	const loadMoreOrders = () => {
		setVisibleOrders((prev) =>
			Math.min(prev + 5, orders.length)
		);
	};

	const handleSearch = (e) => {
		setSearchQuery(e.target.value.toLowerCase());
	};

	const filteredOrders = orders.filter(
		(order) =>
			order.rdName
				.toLowerCase()
				.includes(searchQuery)
			// 	 ||
			// order.order_date.toLowerCase().includes(searchQuery)
	);

	// field names of records
	// const enum_orders = ["id", "customer_name", "order_date", "status", "total_amount"]

	// const sortByColumn = (index) => {
	// 	// sorting logic
	// };

	const table_head = [
		'Order ID',
		'Report Name',
		'Email',
		'Plan',
		'Order Date',
		'Price',
		// 'Action',
	];

	return (
		<main className='orders'>
			<div className='orders__Top'>
				<div className='orders__Searchbar'>
					<input
						type='text'
						placeholder='Search...'
						value={searchQuery}
						onChange={handleSearch}
					/>
				</div>
			</div>
			<div className='orders__Table'>
				<table>
					<thead>
						<tr>
							{table_head.map((title, index) => (
								<th key={index}>
									<div>
										{title}
										<svg
											xmlns='http://www.w3.org/2000/svg'
											fill='none'
											viewBox='0 0 24 24'
											strokeWidth={1.5}
											stroke='currentColor'
											className='size-6'
											// onClick={() => sortByColumn(index)}
										>
											<path
												strokeLinecap='round'
												strokeLinejoin='round'
												d='M3 7.5 7.5 3m0 0L12 7.5M7.5 3v13.5m13.5 0L16.5 21m0 0L12 16.5m4.5 4.5V7.5'
											/>
										</svg>
									</div>
								</th>
							))}
						</tr>
					</thead>
					<tbody className='h-[50vh] overflow-y-scroll'>
						{filteredOrders
							.slice(0, visibleOrders)
							.map((item) => (
								<tr key={item.id}>
									<td>{item.id}</td>
									<td>{item.rdName.length > 20 ? item.rdName.slice(0, 20) + '...' : item.rdName}</td>
									<td>{item.customerEmail}</td>
									<td>{item.planName}</td>
									<td>{new Date(item.createdAt).toLocaleString()}</td>
									<td>${item.price}</td>
									{/* <td>
										<Button
											className='bg-[#042E3E] text-white px-4 py-2'
											text='Download Invoice'
											onClick={() => {}}
										/>
									</td> */}
								</tr>
							))}
					</tbody>
				</table>
			</div>
			{visibleOrders < filteredOrders.length && (
				<div
					className='orders__LoadMore'
					onClick={loadMoreOrders}>
					<p>Load More...</p>
				</div>
			)}
		</main>
	);
};

export default OrdersList;
