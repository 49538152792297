import { apiClient } from './apiclient';

export const postReports = async (payload) => {
	const apiUrl = '/reports/admin';
	// for (let pair of payload.entries()) {
	// 	console.log(pair[0] + ', ' + pair[1]);
	// }
	const res = await apiClient({
		method: 'post',
		url: apiUrl,
		headers: {
			'Content-Type': 'multipart/form-data',
		},
		data: payload,
	});
	return res;
};
export const getReports = async ({ category='', subCategory='', page = 1, limit = 10 }) => {
	let apiUrl = `/research-documents?pagination[page]=${page}&pagination[pageSize]=${limit}`;

	if (category) {
		apiUrl += `&filters[category][slug][$eq]=${category}`;
	}

	if (subCategory) {
		apiUrl += `&filters[sub_category][slug][$eq]=${subCategory}`;
	}

	apiUrl += '&populate=*';

	const res = await apiClient({
		method: 'get',
		url: apiUrl,
		// params: params,
	});
	return res;
};

export const getDetailedReport = async (params) => {
	const apiUrl = `/research-documents?filters[slug][$eq]=${params.urlSlug}&populate=*`;

	const res = await apiClient({
		method: 'get',
		url: apiUrl,
		// params: params,
	});
	return res;
};
export const getPosts = async (params) => {
	const apiUrl = '/posts';

	const res = await apiClient({
		method: 'get',
		url: apiUrl,
		params: params,
	});
	return res;
};
