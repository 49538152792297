import React, { useEffect, useState, useRef } from 'react';
import {
	Link,
	NavLink,
	useNavigate,
} from 'react-router-dom';

import Button from '../Button/Button.component';
import {
	ACCESS_TOKEN_KEY,
	REFRESH_TOKEN_KEY,
} from '../../constants/token.constant';
import Token from '../../lib/token';
import CookieToken from '../../lib/cookie-token';
import { jwtDecode } from 'jwt-decode';
import { isTokenExpired } from '../../lib/auth';
import { useDispatch, useSelector } from 'react-redux';
import { selectCategory } from '../../store/selector/selector';
import { fetchCategory } from '../../store/reducers/categories';
import navLinks from '../../constants/static.json';
import { UserCircle, Search, XIcon } from 'lucide-react';
import {
	Tooltip,
	TooltipContent,
	TooltipTrigger,
} from '../Modal/tooltip';
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from '../Modal/Popover';
import { apiClient } from '../../repositories/apiclient';
import Loader from '../Loader/Loader.component';

const Navbar = () => {
	const companyLinks = navLinks.companyLinks;
	const resourcesLinks = navLinks.articleLinks;
	const isLoading = false;
	const error = null;
	const dispatch = useDispatch();
	const category = useSelector(selectCategory);
	const navigate = useNavigate();

	const token = CookieToken.getToken(ACCESS_TOKEN_KEY);
	const [user, setUser] = useState();
	const [loggedIn, setLoggedIn] = useState(false);
	const [searchModal, setSearchModal] = useState(false);
	const [isMenuOpen, setIsMenuOpen] = useState(false);
	const [activeMenu, setActiveMenu] = useState(null);

	const handleMenuToggle = (menu) => {
		setActiveMenu((prevMenu) => (prevMenu === menu ? null : menu));
	};


	const openSearchModal = () => {
		setSearchModal(true);
	};

	const closeSearchModal = () => {
		setSearchModal(false);
	};

	const logout = () => {
		document.cookie = `${ACCESS_TOKEN_KEY}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
		document.cookie = `${REFRESH_TOKEN_KEY}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
		localStorage.removeItem('userData');
		setUser(null);
		setLoggedIn(false);
		navigate('/sign-in');
	};

	useEffect(() => {
		dispatch(fetchCategory());
		if (token) {
			if (!isTokenExpired(token)) {
				const usr = jwtDecode(token);
				setUser({
					...user,
					token: token,
					name: usr?.user?.username,
					image: usr?.user?.image,
					email: usr?.user?.email,
				});
			} else {
				CookieToken.removeToken(ACCESS_TOKEN_KEY);
				Token.removeToken(REFRESH_TOKEN_KEY);
			}
		}
		if (localStorage.getItem('userData') && !isTokenExpired(token)) {
			setLoggedIn(true);
		}
	}, [loggedIn]);

	const toggleMenu = () => {
		setIsMenuOpen(!isMenuOpen);
	};

	return (
		<nav className='bg-[#EDEDED] sticky top-0 z-20 w-full'>
			<div className='bg-[#042E3E] flex items-center justify-end w-full gap-8 py-2 pr-6 text-[1.5rem] font-[400] text-white max-lg:flex-wrap max-lg:px-[1rem]'>
				<a href='tel:+12136167815'>+1 213 616 7815</a>
				<a href='tel:+447700142117'>+44 7700 142117</a>
				<a href='tel:+918134945168'>+91 81349 45168</a>
				<a href='mailto:inquiry@horizon-markets.com'>inquiry@horizon-markets.com</a>
			</div>
			<div className='flex justify-between items-center w-[90%] max-xl:w-[95%] mx-auto md:px-custom'>
				<Link to='/' className='navbar__Left'>
					<img
						src='/HMSLogo.png'
						alt='HMS Logo'
						className='w-[8.5rem] h-auto'
					/>
				</Link>
				<div className='w-full md:flex md:w-auto max-lg:hidden'>
					<ul className='nav__MainLinks'>
						<li className='group'>
							<button className='flex items-center gap-[1rem]'>
								Industries
								<img
									src='/NavbarDropdownArrow.svg'
									alt='arrow'
									className='w-[1rem]'
								/>
							</button>
							<div className='hidden group-hover:block mt-1 bg-[#EDEDED] fixed top-[8rem] h-fit overflow-y-scroll'>
								<div className='grid grid-cols-2 grid-rows-8 grid-flow-col gap-4 p-4 w-full mx-auto py-12'>
									{!isLoading && !error && category?.length > 0 && [...category].sort((a, b) => a.name.localeCompare(b.name)).map((e) => (
										<div key={e.id} className='relative group nvf'>
											<NavLink
												to={`/reports/${e.slug}`}
												className='flex text-white hover:underline'>
												{e.name}
											</NavLink>
											{/* <div className='nvf absolute left-0 overflow-y-scroll max-h-[30rem] top-[100%] hidden group-hover:bg-[#0e3545] group-hover:flex z-[1] flex-col min-w-[25rem]'>
												{e.sub_categories.map((i) => (
													<NavLink
														key={i.id}
														className='renderCategory__Child'
														to={`/reports/${i.id}`}>
														{i.name}
													</NavLink>
												))}
											</div> */}
										</div>
									))}
								</div>
								{/* <IndustriesDropdownContent /> */}
							</div>
						</li>
						<li className='group'>
							<button className='flex items-center gap-[1rem]'>
								Company
								<img
									src='/NavbarDropdownArrow.svg'
									alt='arrow'
									className='w-[1rem]'
								/>
							</button>
							<div className='hidden group-hover:block mt-1 bg-[#EDEDED] fixed top-[8rem] h-fit overflow-y-scroll'>
								<CompanyDropdown />
							</div>
						</li>
						<li className='group'>
							<button className='flex items-center gap-[1rem]'>
								Resources
								<img
									src='/NavbarDropdownArrow.svg'
									alt='arrow'
									className='w-[1rem]'
								/>
							</button>
							<div className='hidden group-hover:block mt-1 bg-[#EDEDED] fixed top-[8rem] h-fit overflow-y-scroll'>
								<ResourcesDropdown />
							</div>
						</li>
						<li>
							<Link to='/faqs'>FAQs</Link>
						</li>
						<li>
							<Search onClick={() => openSearchModal()} className='cursor-pointer' />
							{
								searchModal && (
									<SearchModal closeSearchModal={closeSearchModal} />
								)
							}
						</li>
						{/* <li>
							<Popover>
								<PopoverTrigger onClick={() => navigate('/dashboard')}>
									<UserCircle />
								</PopoverTrigger> */}
						{/* <PopoverContent className='flex p-4 bg-[#272626] text-white w-32 rounded-md text-xl'>
									<ul className='flex flex-col gap-5'>
										{
											loggedIn ? (
												<>
													<li className='cursor-pointer'>
														<Link to='/dashboard'>Profile</Link>
													</li>
													<li className='cursor-pointer'>
														<Link to='/dashboard'>Orders</Link>
													</li>
													<li onClick={logout} className='cursor-pointer'>
														Logout
													</li>
												</>
											) : (
												<>
													<li className='cursor-pointer'>
														<Link to='/sign-in'>Login</Link>
													</li>
												</>
											)
										}
									</ul>
								</PopoverContent> */}
						{/* </Popover>
						</li> */}
					</ul>
				</div>
				{/* Responsive Menu */}
				<div className="md:hidden relative">
					<button onClick={toggleMenu} className="text-2xl">
						<svg
							xmlns="http://www.w3.org/2000/svg"
							fill="none"
							viewBox="0 0 24 24"
							strokeWidth={2}
							stroke="currentColor"
							className="w-8 h-8"
						>
							<path
								strokeLinecap="round"
								strokeLinejoin="round"
								d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
							/>
						</svg>
					</button>
					{isMenuOpen && (
						<div className="absolute right-0 bg-white shadow-lg w-[25rem]">
							<ul className="menu p-4">
								<li className='mb-2'>
									<button
										onClick={() => handleMenuToggle('industries')}
										className="flex justify-between items-center w-full text-[1.2rem]"
									>
										Industries
									</button>
									{activeMenu === 'industries' && (
										<ul className="bg-gray-100 p-2">
											{category?.map((item) => (
												<li key={item.id}>
													<NavLink
														to={`/reports/${item.slug}`}
														onClick={() => setIsMenuOpen(!isMenuOpen)}
														className="block p-2 hover:bg-gray-200 text-[1rem]"
													>
														{item.name}
													</NavLink>
												</li>
											))}
										</ul>
									)}
								</li>
								<li className='mb-2'>
									<button
										onClick={() => handleMenuToggle('company')}
										className="flex justify-between items-center w-full text-[1.2rem]"
									>
										Company
									</button>
									{activeMenu === 'company' && (
										<ul className="bg-gray-100 p-2">
											{companyLinks.map((items) => (
												<li key={items.id}>
													<NavLink
														to={items.path}
														onClick={() => setIsMenuOpen(!isMenuOpen)}
														className="block p-2 text-[1rem] hover:bg-gray-200">
														{items.name}
													</NavLink>
												</li>
											))}
										</ul>
									)}
								</li>
								<li className='mb-2'>
									<button
										onClick={() => handleMenuToggle('resources')}
										className="flex justify-between items-center w-full text-[1.2rem]"
									>
										Resources
									</button>
									{activeMenu === 'resources' && (
										<ul className="bg-gray-100 p-2">
											{resourcesLinks?.map((items) => (
												<li key={items.id}>
													<NavLink
														to={items.path}
														onClick={() => setIsMenuOpen(!isMenuOpen)}
														className="block p-2 text-[1rem] hover:bg-gray-200">
														{items.name}
													</NavLink>
												</li>
											))}
										</ul>
									)}
								</li>
								<li>
									<Link to="/faqs" onClick={() => setIsMenuOpen(!isMenuOpen)} className='text-[1.2rem]'>FAQs</Link>
								</li>
							</ul>
						</div>
					)}
				</div>
			</div>
		</nav>
	);
};

const SearchModal = ({ closeSearchModal }) => {
	const [searchQuery, setSearchQuery] = useState('');
	const [results, setResults] = useState([]);
	const [loading, setLoading] = useState(false);

	const handleClose = (e) => {
		if (e.target.classList.contains('fixed')) {
			closeSearchModal();
		}
	};

	const fetchResults = async (query) => {
		if (!query) {
			setResults([]);
			return;
		}
		setLoading(true);
		try {
			const response = await apiClient.get(
				`/research-documents`,
				{
					params: {
						populate: '*',
						filters: {
							title: {
								$containsi: query,
							},
						},
					},
				}
			);
			setResults(response.data.data);
		} catch (error) {
			console.error('Error fetching data:', error);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		const timeoutId = setTimeout(() => {
			fetchResults(searchQuery);
		}, 500); // Debounce API calls
		return () => clearTimeout(timeoutId);
	}, [searchQuery]);

	return (
		<div className='fixed top-[10vh] left-[20vh] w-[80vw] h-[80vh] bg-[#042E3E]/95 z-[100] pt-20 flex flex-col gap-4 items-center rounded-xl' onClick={handleClose}>
			<button
				onClick={closeSearchModal}
				style={{
					color: "#fff",
					position: "absolute",
					right: "10px",
					top: "10px",
					width: "fit-content",
				}}
			>
				<XIcon />
			</button>
			<div className='w-[70%] flex flex-col items-center justify-center gap-4'>
				<input
					className='w-full text-[3.4rem] text-white text-center p-2 bg-transparent border-b-2 border-[#fff] outline-none'
					type='text'
					placeholder='Search...'
					value={searchQuery}
					onChange={(e) => setSearchQuery(e.target.value)}
				/>

				<div className='w-full h-[55vh] overflow-y-scroll' onClick={handleClose}>
					{
						loading ?
							<Loader />
							: results.length > 0 ?
								results.map((result) => (
									<Link
										key={result.id}
										to={`/report/${result.slug}`}
										onClick={closeSearchModal}
										style={{
											color: "#fff",
										}}
										className='underline-offset-4 hover:underline'
									>
										<h1>{result.title}</h1>
									</Link>
								))
								: <p>No results found</p>
					}
				</div>
			</div>
		</div>
	);
};

const IndustriesDropdownContent = () => (
	<div className='nav__Cont w-[90%] mx-auto'>
		<div className='nav__BoxThree'>
			<div className='nav__BoxThree--Left'>
				<h1>Industries</h1>
				<p>
					From brand strategy to sales performance, we blend a unique combination
					of expertise, advanced analytics, products and platforms, creating the
					most meaningful understanding of how people think and act.
				</p>
			</div>
		</div>
		<ContactSection />
	</div>
);

const ResourcesDropdown = () => {
	const resourcesLinks = navLinks.articleLinks;

	return (
		<>
			<div className='grid grid-cols-1 gap-4 p-4 w-full mx-auto py-12'>
				{resourcesLinks?.map((items) => (
					<Link
						key={items.id}
						to={items.path}
						className='flex text-white hover:underline capitalize'>
						{items.name}
					</Link>
				))}
			</div>
			{/* <div className='w-[90%] mx-auto'>
				<h1 className='text-[3.2rem] font-[400] text-[#FFF]'>
					Capabilities
				</h1>
				<p className='mt-[2rem] text-[1.6rem] font-[500] text-[#FFF] max-w-[70%]'>
					From brand strategy to sales performance, we blend a unique combination 
					of expertise, advanced analytics, products and platforms, creating the 
					most meaningful understanding of how people think and act.
				</p>
				<ContactSection />
			</div> */}
		</>
	);
};

const CompanyDropdown = () => {
	const companyLinks = navLinks.companyLinks;
	const articleLinks = navLinks.articleLinks;

	return (
		<div>
			{/* <h1 className='text-[#FFF] text-[1.8rem] font-[700] uppercase whitespace-nowrap mb-[2rem]'>
				COMPANY
			</h1> */}
			<div className='grid grid-cols-1 gap-4 p-4 w-full mx-auto py-12'>
				{companyLinks.map((items) => (
					<Link
						key={items.id}
						to={items.path}
						className='flex text-white hover:underline capitalize'>
						{items.name}
					</Link>
				))}
			</div>

			{/* <h1 className='text-[#FFF] text-[1.8rem] font-[700] uppercase whitespace-nowrap my-[2rem]'>
				ARTICLE
			</h1>
			<div className='grid grid-cols-4 gap-4 p-4 mx-auto'>
				{articleLinks.map((items) => (
					<Link
						key={items.id}
						to={items.path}
						className='renderCategory__name flex justify-center'>
						{items.name}
					</Link>
				))}
			</div>
			<ContactSection /> */}
		</div>
	);
};

const ContactSection = () => (
	<div className='nav__dropdownDetails'>
		<div className='nav__dropdownLeft flex gap-[8rem]'>
			<div className='nav__InfoOne flex flex-col gap-[0.5rem]'>
				<h1 className='uppercase'>Contact</h1>
				<a href='tel:+447700142117' className='text-[1.4rem] font-[500] text-[#F4F4F4]'>
					+44 7700 142117
				</a>
				<a href='tel:+12136167815' className='text-[1.4rem] font-[500] text-[#F4F4F4]'>
					+1 213 616 7815
				</a>
			</div>
			<div className='nav__InfoOne flex flex-col gap-[0.5rem]'>
				<h1 className='uppercase'>Email</h1>
				<a href='mailto:info@horizon-markets.com' className='text-[1.4rem] font-[500] text-[#F4F4F4]'>
					info@horizon-markets.com
				</a>
			</div>
			<div className='nav__InfoOne flex flex-col'>
				<h1 className='uppercase'>Follow</h1>
				<div className='flex gap-[1rem]'>
					<Link to='https://www.linkedin.com/company/horizon-market-strategies/' target='_blank'>
						<span className='text-[1.4rem] font-[500] text-[#F4F4F4]'>
							Facebook
						</span>
					</Link>
					<Link to='https://www.linkedin.com/company/horizon-market-strategies/' target='_blank'>
						<span className='text-[1.4rem] font-[500] text-[#F4F4F4]'>
							Linkedin
						</span>
					</Link>
					<Link to='https://www.youtube.com/@HorizonmarketStrategies' target='_blank'>
						<span className='text-[1.4rem] font-[500] text-[#F4F4F4]'>
							Youtube
						</span>
					</Link>
				</div>
			</div>
		</div>
		<div className='nav__dropdownRight'>
			<Link to='/sign-in'>
				<Button
					type='button'
					text='Login'
					className='mt-[2rem] Button__WhiteSquare w-[25rem] py-[2rem]'
				/>
			</Link>
		</div>
	</div>
);

export default Navbar;