import { apiClient, stripeClient } from './apiclient';

export const postCategories = async (payload) => {
	const apiUrl = '/category/admin';
	const res = await apiClient({
		method: 'post',
		url: apiUrl,
		data: { ...payload },
	});
	return res;
};
export const postSubCategories = async (payload) => {
	const apiUrl = '/category/admin/sub';
	const res = await apiClient({
		method: 'post',
		url: apiUrl,
		data: { ...payload },
	});
	return res;
};
export const getCategories = async (params) => {
	const apiUrl = '/categories?populate=*';

	const res = await apiClient({
		method: 'get',
		url: apiUrl,
		params: params,
	});
	return res;
};
export const getSubCategories = async (params) => {
	const apiUrl = '/sub-categories?populate=*';

	const res = await apiClient({
		method: 'get',
		url: apiUrl,
		params: params,
	});
	return res;
};
export const postByor = async (payload) => {
	const apiUrl = '/byors';

	const formattedPayload = {
		data: {
			byor: payload,
		}
	};

	const res = await apiClient({
		method: 'post',
		url: apiUrl,
		data: formattedPayload,
	});

	await stripeClient({
		method: 'post',
		url: apiUrl,
		data: payload,
	})
	.then((res) => {
		console.log(res);
	})
	.catch((err) => {
		console.log(err);
	});

	const hubspotPayload = {
		name: payload.name,
		email: payload.email,
		phone: payload.phone,
		position: payload.position,
		company: payload.company,
		from: "BYOR"
	}

	await stripeClient.post('/hubspot', hubspotPayload)
	.then((res) => {
		console.log(res);
	})
	.catch((err) => {
		console.log(err);
	});

	return res;
};
